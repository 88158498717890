import React from "react";
import styled from "styled-components";
import Loader from "./Loader";

export default function FullScreenLoader() {
  return (
    <FullScreenLoaderWrapper>
      <Loader color="#084597" />
    </FullScreenLoaderWrapper>
  );
}

const FullScreenLoaderWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  background: #fff;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    height: 100px;
    width: 100px;
  }
`;
