import { lazy } from "react";

const OnBoarding = lazy(() => import("pages/onboarding/index"));
const RegistrationInput = lazy(() => import("pages/onboarding-input"));
const PatientInformation = lazy(() => import("pages/patient-information"));
const AddressInformation = lazy(
  () => import("pages/patient-information/personal-information")
);
const EmergencyInformation = lazy(
  () => import("pages/patient-information/emergency-information")
);
const Login = lazy(() => import("pages/login"));
const LoginPatient = lazy(() => import("pages/patient/login"));
const AddPatient = lazy(() => import("pages/patient/add-patient"));
const PatientList = lazy(() => import("pages/patient/patient-list"));
const TreatmentHistory = lazy(() => import("pages/patient/treatment-history"));

const Home = lazy(() => import("pages/home"));

export const routes = [
  {
    path: "/",
    isPublic: true,
    component: <Login />,
  },
  {
    path: "/login",
    isPublic: true,
    component: <Login />,
  },

  {
    path: "/patient/login",
    isPublic: true,
    component: <LoginPatient />,
  },
  {
    path: "/patient/add-patient",
    isPublic: true,
    component: <AddPatient />,
  },
  {
    path: "/patient/list",
    isPublic: true,
    component: <PatientList />,
  },
  {
    path: "/patient/treatment-history",
    isPublic: true,
    component: <TreatmentHistory />,
  },
  {
    path: "/onboarding",
    isPublic: true,
    component: <OnBoarding />,
    withoutLayout: true,
  },
  {
    path: "/get-patient-number",
    isPublic: true,
    component: <RegistrationInput />,
    withoutLayout: true,
  },
  {
    path: "/patient-information",
    isPublic: true,
    component: <PatientInformation />,
    withoutLayout: true,
  },
  {
    path: "/address-information",
    isPublic: true,
    component: <AddressInformation />,
    withoutLayout: true,
  },
  {
    path: "/emergency-information",
    isPublic: true,
    component: <EmergencyInformation />,
    withoutLayout: true,
  },
  {
    path: "/home",
    isPublic: true,
    component: <Home />,
  },
];
