import FullScreenLoader from "components/FullScreenLoader";
import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import MyRoute from "./MyRoutes";
import { routes } from "./routes";

const AppRoutes = () => {
  return (
    <React.Suspense fallback={<FullScreenLoader />}>
      <Routes>
        {routes.map(
          (
            { path, component, isPublic = false, withoutLayout = false },
            index
          ) => (
            <Route
              key={index}
              path={path}
              element={
                <MyRoute
                  isPublic={isPublic}
                  withoutLayout={withoutLayout}
                  component={component}
                />
              }
            />
          )
        )}
      </Routes>
    </React.Suspense>
  );
};

export default React.memo(AppRoutes);
