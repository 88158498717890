import React, { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
interface MyRouteType {
  component: React.ReactElement;
  isPublic?: boolean;
  withoutLayout?: boolean;
  unauthorizedRedirection?: string;
}

const MyRoute = ({
  component,
  isPublic,
  unauthorizedRedirection,
  withoutLayout,
}: MyRouteType) => {
  // const navigate = useNavigate();
  // const isLogedIn = useIsLogedIn();
  let location = useLocation();
  // const user: any = useLoggedInUser();
  // useEffect(() => {
  //   if (!isPublic) {
  //     if (!isLogedIn) {
  //       navigate(unauthorizedRedirection ? unauthorizedRedirection : "/", {
  //         replace: true,
  //       });
  //     }
  //   } else if (isLogedIn && user?.checkedDetails) {
  //     navigate(routeAfterLoginTo, {
  //       replace: true,
  //     });
  //   }
  // }, [
  //   unauthorizedRedirection,
  //   isLogedIn,
  //   user?.checkedDetails,
  //   isPublic,
  //   navigate,
  // ]);

  // var pathName = useMemo(() => location.pathname, [location.pathname]);

  useEffect(() => {
    console.log("Scroll to top now");
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: "smooth",
    });
  }, [location]);

  // const { checkByURL } = useScreenPermission(isPublic);
  return true ? (
    withoutLayout ? (
      component
    ) : (
      <LayoutWrapper>
        <ComponentWrapper>
          {/* {!isPublic ? <Navbar /> : null} */}
          {component}
        </ComponentWrapper>
        {/* <Footer /> */}
      </LayoutWrapper>
    )
  ) : null;
};

export default React.memo(MyRoute);

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;
