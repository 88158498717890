import * as React from "react";
import { SVGProps, memo } from "react";

interface LoaderProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const Loader = (props: LoaderProps) => {
  const { color = "#fff" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      xmlSpace="preserve"
      {...props}
    >
      <circle
        fill="none"
        stroke={color}
        strokeWidth={4}
        cx={50}
        cy={50}
        r={44}
        style={{
          opacity: 0.7,
        }}
      />
      <circle fill={color} stroke="#fff" strokeWidth={3} cx={8} cy={54} r={6}>
        <animateTransform
          attributeName="transform"
          dur="2s"
          type="rotate"
          from="0 50 48"
          to="360 50 52"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
  );
};

export default memo(Loader);
